/* eslint-disable linebreak-style */
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'
import ReactSwipe from 'react-swipe';
import { RxArrowLeft, RxArrowRight } from 'react-icons/rx';
import { HiArrowRight } from 'react-icons/hi2';
import { BsArrowRight } from 'react-icons/bs';
import HomeBanner from '../../data/homeBanner';
import historyImg from '../../assets/images/companies/history-video-thumbnail.png';
import PlayIcon from '../../assets/images/play-icon.png';
import BuildingIllustration from '../../assets/images/building-illustration.png';
import Projects from '../../data/projects';
import './Home.css';
import '../../components/sharedStyle.css';
import { commonClassNames, styles } from '../../utils/theme/styles';
import OurPartners from '../../components/ourPartners/OurPartners';
import { getStories, setSelectedStory } from '../../store/app/actions';
import { connect } from 'react-redux';

import SkylineCompany from '../../assets/images/companies/skyline-company.png';
import SecondSkylineCompany from '../../assets/images/companies/skyline-properties-3.jpg';
import ThirdSkylineCompany from '../../assets/images/companies/skyline-3.jpg';

import DermElevatorCompany from '../../assets/images/companies/derm-elevators-company.png';
import SecondDermElevatorCompany from '../../assets/images/companies/derm-elevators.jpg';
import ThirdDermElevatorCompany from '../../assets/images/companies/derm-elevators-3.jpg';

import CoastConcreteCompany from '../../assets/images/companies/coast-concrete-company.png';
import SecondCoastConcreteCompany from '../../assets/images/companies/coast-concrete.jpg';
import ThirdCoastConcreteCompany from '../../assets/images/companies/coast-concrete-3.jpg';

import DermElectricCompany from '../../assets/images/companies/derm-electric-company.png';
import SecondDermElectricCompany from '../../assets/images/companies/derm-electric.jpg';
import ThirdDermElectricCompany from '../../assets/images/companies/derm-electric-3.jpg';

import dermElevatorLogo from '../../assets/images/derm-elevators.png';
import dermLogo from '../../assets/images/logo.png';
import skylineLogo from '../../assets/images/skyline-logo.png';
import coastConcreteLogo from '../../assets/images/concrete.png';


function Home({
  stories,
  loading,
  onGetStories,
  onSetSelectedStory,
}) {

  const _history = useHistory()
  let toggle = 1

  const container = 'border-4 px-8 py-[40px] rounded-[34px]';
  const title = 'headingText mb-[40px] text-[31px] font-bold';
  const descStyle = 'tagText text-[20px]';
  const cardStyle = 'lg:w-[1000px] xl:w-[1400px] 2xl:w-[1600px] aitems-center bg-white';

  const companiesContainer = 'lg:flex place-content-center items-center bg-[#F8F8F8] sm:mx-[0] md:mx-[0px] lg:mx-[8%] xl:mx-[10%] 2xl:mx-[12%]'
  const logoContainer = 'h-[100px] grid content-center align-center';
  const imgContainer = 'sm:w-[100%] md:w-[100%] lg:w-[100%] 2xl:w-[100%]';
  const imgStyle = 'sm:w-[100%] sm:h-[400px] md:w-[500px] md:h-[500px] lg:w-[100%] lg:h-[600px] xl:w-[100%] xl:h-[600px] 2xl:w-[100%] 2xl:h-[700px] img-object-fit-cover cursor-pointer';
  const bioClass = 'sm:w-[100%] sm:h-[400px] md:w-[500px] md:h-[500px] lg:w-[42%] lg:h-[600px] xl:w-[41%] xl:h-[600px] 2xl:w-[38.5%] 2xl:h-[700px] sm:mt-[-400px] md:mt-[-500px] lg:mt-[-500px] xl:mt-[-600px] 2xl:mt-[-700px] font-regular lg:text-[16px] xl:text-[18px]'

  let _interval, _dermElectric, _dermElevator, _coastConcrete, _skyline
  useEffect(() => {
    _dermElectric = document.getElementById('derm-electric-img')
    _dermElevator = document.getElementById('derm-elevators')
    _coastConcrete = document.getElementById('coast-concrete')
    _skyline = document.getElementById('skyline')
    onGetStories()
    _interval = setInterval(() => {
      if (toggle === 0) {
        _dermElectric.setAttribute('src', DermElectricCompany)
        _dermElevator.src = DermElevatorCompany
        _coastConcrete.src = CoastConcreteCompany
        _skyline.src = SkylineCompany
      } else if (toggle === 1) {
        _dermElectric.setAttribute('src', SecondDermElectricCompany)
        _dermElevator.src = SecondDermElevatorCompany
        _coastConcrete.src = SecondCoastConcreteCompany
        _skyline.src = SecondSkylineCompany
      } else if (toggle === 2) {
        _dermElectric.setAttribute('src', ThirdDermElectricCompany)
        _dermElevator.src = ThirdDermElevatorCompany
        _coastConcrete.src = ThirdCoastConcreteCompany
        _skyline.src = ThirdSkylineCompany
      }
      toggle = toggle < 2 ? toggle += 1 : 0
    }, 4000)

    return () => {
      if (_interval) clearInterval(_interval)
    }
  }, [])

  // useEffect(() => {
  // onGetStories()
  // window.scrollTo(0, 0)
  // let _interval
  // return () => {
  //   if (_interval) clearInterval(_interval)
  // }
  // }, [])

  // for sliders
  let slide;
  return (
    <div className="" style={{ transition: 'all 1s' }}>
      <ReactSwipe
        className=""
        swipeOptions={{ continuous: true, auto: 3000, speed: 2000 }}
        ref={(y) => {
          slide = y;
        }}
      >
        {/* banner */}
        {HomeBanner.map((data) => (
          <div
            key={data?.id}
            style={{
              backgroundImage: `url(${data.imgURL})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }}
            className="">
            <div className={commonClassNames().transparentImageForegroundContentContainerClass} style={styles.transparentBackgroundImage}>
              <div className={commonClassNames().transparentImageForegroundContentClass}>
                <div className="grid gap-5">
                  <h1 className={commonClassNames().transparentImageForegroundTitle}>
                    Dedication
                    <br /> To Achieving <br />
                    Excellence
                  </h1>

                  <p className="text-[16.32px] text-white">
                    We have more than 2 decades with a solid reputation for
                    <br />
                    professionalism, expertise, and reliability
                  </p>
                </div>
                <div className="grid lg:justify-items-start">
                  <button
                    type="submit"
                    className="bg-[#88000F] opacity-100 hover:bg-[#88000FE5] text-white font-semibold h-[61.95px] w-[156.91px] inline-flex items-center gap-2 justify-center"
                  >
                    <Link to="/about/" style={{ backgroundColor: '#88000F' }}>
                      <span>Explore More</span>
                    </Link>
                    <BsArrowRight className="text-xl" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </ReactSwipe>
      {/* slider buttons */}
      <div className="flex justify-end absolute lg:top-[82vh] xl:top-[82vh] 2xl:top-[82vh] sm:top:[62vh] right-[0px]">
        {/* eslint-disable-next-line */}
        <button
          type="button"
          onClick={() => slide.prev()}
          className="bg-white font-semibold h-[65px] w-[80px] inline-flex items-center gap-0 text-black place-content-center"
        >
          <RxArrowLeft className="text-3xl lg:text-4xl" />
        </button>
        {/* eslint-disable-next-line */}
        <button
          type="button"
          onClick={() => slide.next()}
          className="bg-[#88000F] font-semibold text-white h-[65px] w-[80px] inline-flex items-center gap-0 place-content-center"
        >
          <RxArrowRight className="text-3xl lg:text-4xl" />
        </button>
      </div>

      {/* Marquee for news..  clients ;-),, SMH */}
      {
        stories.length > 0 && (
          <div className='bg-[#88000F] text-white text-[18px] h-[60px] flex flex-row items-center cursor-pointer lg:px-[160px] md:px-[20px] mt-[1px]'
            onClick={() => { _history.push('/stories/') }}
          >
            <marquee >Latest News: {stories[0].story_title}</marquee>
            {/* <Link to={'/stories/'} 
            className='absolute right-0 w-[160px] h-[60px] lg:top-[90vh] xl:top-[90vh] 2xl:top-[90vh] sm:top:[90vh] right-[0px] z-40 flex flex-row items-center justify-center'>
              More <BsArrowRight className='ml-[10px]' />
            </Link> */}
          </div>
        )
      }
      {/* features */}
      <div className="bg-[#F8F8F8] lg:px-16 sm:px-[10px] xl:px-16 2xl:px-[200px] 3xl:px-[300px] md:pt-[20px] lg:pt-[50px] lg:h-[850px]">
        <h1 className="tagText text-[26px] text-[#434343] font-medium mb-8 sm:mt-[60px] md:mt-[0px] sm:px-[20px]">
          FEATURE
        </h1>
        <div className={"lg:px-[0px] sm:px-[15px]"} >
          <h1 className="headingText text-[#88000F] font-bold text-[35px] lg:text-[45px] mb-[30px]">
            Our Focus
          </h1>
          <div className="grid lg:flex gap-6">
            <div
              className={`${container} bg-[#88000F] w-full lg:w-[34%] 2xl:w-[34%] border-[#D8D8D8]`}
            >
              <h1 className={`${title} text-white`}>
                Customer Service Experience
              </h1>
              <p className={`${descStyle} text-[#DBB2B7]`}>
                We have all been on both ends of the customer service spectrum.
                You&apos;ve likely received a complaint from a customer or
                client or been the customer complaining about a substandard
                service or product. Neither is a nice place to be.
              </p>
            </div>
            <div
              className={`${container} w-full lg:w-[33%] 2xl:w-[33%] bg-[#434343]  border-[#716F6F] lg:relative lg:top-[75px]`}>
              <h1 className={`${title} text-white`}>
                Business Growth Strategy
              </h1>
              <p className={`${descStyle} text-white`}>
                We have all been on both ends of the customer service spectrum.
                You&apos;ve likely received a complaint from a customer or
                client or been the customer complaining about a substandard
                service or product. Neither is a nice place to be.
              </p>
            </div>
            <div
              className={`${container} w-full lg:w-[33%] 2xl:w-[33%] bg-[#88000F]`}
            >
              <h1 className={`${title} text-white`}>Brand</h1>
              <p className={`${descStyle} text-[#DBB2B7]`}>
                So much focus in the world nowadays is on one&apos;s
                &quot;personal brand.&quot; When I say &quot;personal
                brand,&quot; however, I mean more than a branded notepad and
                embroidered workwear. Gone are the days of limited people
                offering your skill or trade.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* our companies */}
      <div id='companies' />
      <div className="mt-[-30px]">
        <div className='bg-[#F8F8F8] lg:px-[10%] lg:pb-[50px] md:px-[0] sm:px-[0]'>
          <h1 className={commonClassNames().tagText} style={{ letterSpacing: '2px' }}>
            OUR SUBSIDIARIES
          </h1>
          <div className="">
            <h1 className={commonClassNames().titleClass}>
              Tanzania&apos;s Premier Contractor
            </h1>
          </div>
          <h1 className={commonClassNames().paragraphTextClass}>
            For more than 25 years, Derm Group has been involved in many major construction and engineering projects,
            gaining invaluable experience and expertise and acquiring a reputation as one of the leading electrical contractors in Tanzania.
          </h1>
        </div>
        <div className="bg-[#F8F8F8] sm:mx-[0] md:mx-[0] lg:px-[0%] xl:px-[0%] 2xl:px-[0%] pb-[100px]">
          <div className={companiesContainer} style={{ borderBottom: '1px solid rgba(80,80,80,0.1)' }}>
            {/* 1 */}
            <div className={`${cardStyle} company-image`}>
              <div className={`${imgContainer}`}>
                <img src={DermElectricCompany} alt="" className={`${imgStyle}`} id='derm-electric-img' />
                <div className={`${bioClass} company-bio`}>
                  <h2 className='text-[#fff] text-[25px] mb-[20px]'>Derm Group (T) LTD</h2>
                  Derm Group (T) Ltd is Class 1 registered with Contractors Registration Board of Tanzania (CRB), it is also the country’s largest and fastest-growing Tanzanian-owned company, with a solid reputation for professionalism, reliability, technical expertise and delivering solutions that are economically viable and technically superior.
                  <a href='https://www.derm.co.tz/' target='_blank' rel='noreferrer'
                    className="bg-[#88000F] my-[50px] rounded-sm hover:bg-grey text-[#FFFFFF] uppercase font-semibold w-[200px] h-[50px] lg:h-[56.95px] lg:w-[236.91px] inline-flex items-center gap-2 justify-center z-40 cursor-pointer"
                  >
                    <span className="text-md tagText">Read more</span>
                  </a>
                </div>
              </div>

              <div className={`${logoContainer}`}>
                <img src={dermLogo} alt="" className={`h-[40px] img-object-fit-cover`} />
              </div>
            </div>
            {/* 2 */}
            <div className={`${cardStyle} company-image`}>
              <div className={`${imgContainer}`}>
                <img src={DermElevatorCompany} alt="" className={`${imgStyle}`} id='derm-elevators' />
                <div className={`${bioClass} company-bio`}>
                  <h2 className='text-[#fff] text-[25px] mb-[20px]'>Derm Elevators Limited</h2>
                  <p>Derm Elevators Limited is Class 1 registered with Contractors Registration Board of Tanzania(CRB) as specialized mechanical contractor in supply, installation and maintenance of elevators and escalators.</p>
                  <br />
                  <p>Derm Elevators are fully authorized to handle all OTIS activities as well as move the brand with pace and technology in Tanzania.</p>
                  <a href='https://www.derm.co.tz/derm-elevators/' target='_blank' rel='noreferrer'
                    className="bg-[#88000F] my-[50px] rounded-sm hover:bg-grey text-[#FFFFFF] uppercase font-semibold w-[200px] h-[50px] lg:h-[56.95px] lg:w-[236.91px] inline-flex items-center gap-2 justify-center z-40 cursor-pointer"
                  >
                    <span className="text-md tagText">Read more</span>
                  </a>
                </div>
              </div>

              <div className={`${logoContainer} bg-white`}>
                <img
                  src={dermElevatorLogo}
                  alt=""
                  className={`h-[100px] img-object-fit-cover`}
                />
              </div>
            </div>
          </div>
          <div className={companiesContainer}>
            {/* 3 */}
            <div className={`${cardStyle} company-image`}>
              <div className={`${imgContainer} `}>
                <img src={CoastConcreteCompany} alt="" className={`${imgStyle} `} id='coast-concrete' />
                <div className={`${bioClass} company-bio`}>
                  <h2 className='text-[#fff] text-[25px] mb-[20px]'>Coast Concrete poles Ltd</h2>
                  <p>Coast Concrete poles Ltd is a local private limited company registered in the united republic of Tanzania in October, 2017.</p><br />
                  <p>
                    The Company is a subsidiary of Derm Group (T) Ltd and was established with prior objective of forming a factory that will be manufacturing concrete poles and carrying on business of selling, supplying and consulting investment in the concrete poles manufacturing by providing commercial, engineering, financial, environmental and asset management related services in concrete poles manufacturing industry.
                  </p>
                  <a href='#'
                    className="bg-[#88000F] my-[50px] rounded-sm hover:bg-grey text-[#FFFFFF] uppercase font-semibold w-[200px] h-[50px] lg:h-[56.95px] lg:w-[236.91px] inline-flex items-center gap-2 justify-center z-40 cursor-pointer"
                  >
                    <span className="text-md tagText">Read more</span>
                  </a>
                </div>
              </div>
              <div className={`${logoContainer} bg-white`}>
                <img
                  src={coastConcreteLogo}
                  alt=""
                  className={`h-[100px] img-object-fit-cover`}
                />
              </div>
            </div>
            {/* 4 */}
            <div className={`${cardStyle} company-image`}>
              <div className={`${imgContainer}`}>
                <img src={SkylineCompany} alt="" className={`${imgStyle}`} id='skyline' />
                <div className={`${bioClass} company-bio`}>
                  <h2 className='text-[#fff] text-[25px] mb-[20px]'>Skyline Properties Limited</h2>
                  <p>
                    Is a part of DERM GROUP was founded and incorporated in Dar es Salaam, Tanzania in the year 2009 by vibrant founders who decided to follow a burning passion and desire to breathe a new life into the real estate and construction sector in Tanzania by bringing a standard living and working environment with a touch of luxury to the affordability of nationals as well as bridging the housing gap that is caused by the increasing population.
                  </p><br />
                  <p>
                    A steady growth has been recorded over the eight years duration of our existence through a hard-earned reputation for quality and dedication to excellence of our distinguished professional team and determination of our founders.
                    Our core areas of specialization are Real estate development and Construction.
                  </p>
                  <a href='#'
                    className="bg-[#88000F] my-[50px] rounded-sm hover:bg-grey text-[#FFFFFF] uppercase font-semibold w-[200px] h-[50px] lg:h-[56.95px] lg:w-[236.91px] inline-flex items-center gap-2 justify-center z-40 cursor-pointer"
                  >
                    <span className="text-md tagText">Read more</span>
                  </a>
                </div>
              </div>

              <div className={`${logoContainer} `}>
                <img src={skylineLogo} alt="" className={`h-[40px] img-object-fit-cover`} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* company history */}
      <div className="md:px-[2%] md:px-[5%] 2xl:px-[5%] md:rounded-[10px] 2xl:rounded-[10px] sm:h-[400px] lg:h-[500px] 2xl:h-[600px] bg-[#F8F8F8] mt-15">
        <div
          style={{
            backgroundImage: `url(${historyImg})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
          className="md:rounded-[10px] 2xl:rounded-[20px] h-full py-32 md:py-16 2xl:py-24 px-2 2xl:px-16"
        >
          <div className="lg:px-16 ml-6 lg:ml-0">
            <h1 className="tagText text-xl uppercase text-white opacity-75">
              OUR HISTORY
            </h1>
            <h1 className="headingText text-[30px]  lg:text-[45px] font-bold text-white mb-4">
              Company history,
              <br /> present and the
              <br /> future.
            </h1>
            <div className="flex items-center space-x-4">
              <img src={PlayIcon} className="h-10 w-10" alt="" />
              <h1 className="tagText text-[21px] text-white">
                Watch the film now
              </h1>
            </div>
          </div>
        </div>
      </div>

      {/* projects */}
      <div className="bg-[#F8F8F8] px-[30px] lg:py-32 sm:py-[40px]">
        <div className="grid gap-6 lg:flex lg:space-x-4 xl:space-x-4 xl:space-x-4">
          {Projects?.map((project) => (
            <div
              key={project.id}
              className="w-full sm:h-[180px] lg:h-[175px] 2xl:h-[175px] lg:w-[20%] bg-[#FFFFFF] rounded-lg shadow-md lg:rounded-3xl grid place-content-center">
              <h1 className="tagText text-[#88000F] font-bold text-[32px]">
                {project.number}
              </h1>
              <h1 className="tagText text-[#88000F] text-[15px]">
                {project.status}
              </h1>
            </div>
          ))}
        </div>
      </div>
      {/* latest news */}
      <div>
        <div className="bg-[#88000F] sm:py-[40px] lg:py-16 lg:px-[8%] sm:px-[8%] 2xl:px-72 w-full lg:w-full lg:h-[700px]">
          <h1 className="tagText uppercase text-[#F18D8D] font-semibold mb-[10px]">
            KEEP UP WITH OUR
          </h1>
          <h1 className="headingText text-[#FFFFFF] text-4xl font-bold mb-4">
            Latest News
          </h1>
          <div className="grid gap-6 lg:flex lg:items-center lg:justify-between z-40">
            <h1 className="headingText text-[#FFFFFF] lg:text-[20px]">
              Check out the featured news from the Derm Group!
              <br />Click the button below to visit our news page
            </h1>

            <button
              type="submit"
              className="bg-[#F2F2F2] hover:bg-grey text-[#171B24] uppercase font-semibold w-[200px] h-[50px] lg:h-[56.95px] lg:w-[236.91px] inline-flex items-center gap-2 justify-center z-40 cursor-pointer"
            >
              <Link to="/stories/">
                <span className="text-md tagText">SEE MORE STORIES</span>
              </Link>
              <HiArrowRight className="font-bold text-xl" />
            </button>
          </div>
        </div>
        {/* news list */}
        <div className="bg-[#FFFFFF] px-6 py-6 lg:py-16 lg:px-32 2xl:px-72 lg:h-[300px]">
          <div className="lg:flex lg:space-x-1 grid gap-4 lg:relative lg:top-[-465px] sm:top-[0px]">
            {stories?.slice(0, 3).map((data) => (
              <div
                key={data.id}
                className=" bg-[#88000F] rounded-lg sm:w-full sm:h-[420px] md:h-[420px] md:w-full lg:h-[640px] lg:w-1/3 z-40 cursor-pointer border-1px] border-[F0F0F0]"
                onClick={() => {
                  onSetSelectedStory(data)
                  _history.push(`/stories/${data.id}/`)
                }}
              >
                <div className="rounded-2xl sm:h-[250px] md:h-[250px] lg:h-[420px]">
                  <img
                    src={data.story_image}
                    alt=""
                    className="sm:h-[250px] sm:h-[250px] lg:h-[400px] w-full cover-image-fit rounded-tl-lg rounded-tr-lg"
                  />
                </div>
                <div className="sm:h-[150px] md:h-[150px] lg:h-[250px] px-6 lg:px-8 grid pt-[10px] sm:pb-[20px] md:pb-[50px]">
                  <h1 className="tagText font-bold text-[24px] text-white">
                    {data.story_title.length > 50 ? `${data.story_title.substr(0, 50)}...` : data.story_title}
                  </h1>
                  <div className="flex items-center justify-between mt-[15px]">
                    <h1 className="tagText text-[#DBB2B7]">
                      {data.story_date}
                    </h1>
                    <HiArrowRight className="text-[#EAB7BD] text-xl lg:text-2xl" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* img */}
        <div className="lg:flex lg:justify-end hidden ">
          <img
            src={BuildingIllustration}
            className="lg:absolute lg:h-[1089px] lg:w-[754px] lg:top-[4390px] xl:top-[3990px] 2xl:top-[4499px] hidden lg:block"
            alt=""
          />
        </div>
      </div>
      <div id="partners">
        <OurPartners />
      </div>
    </div>
  );
}

Home.propTypes = {
  stories: PropTypes.array,
  onGetStories: PropTypes.func,
  onSetSelectedStory: PropTypes.func,
  selectedStory: PropTypes.object,
  loading: PropTypes.bool
}

const mapStateToProps = ({
  app: { stories, selectedStory, loading }
}) => ({
  stories, selectedStory, loading
})

const mapDispatchToProps = dispatch => ({
  onGetStories: () => dispatch(getStories()),
  onSetSelectedStory: payload => dispatch(setSelectedStory(payload))
})


export default connect(mapStateToProps, mapDispatchToProps)(Home);
